.color-dark--soft {
  color: var(--color-dark--soft);
}

.color-01 {
  color: var(--color-01);
}

.color-02 {
  color: var(--color-02);
}

.bg-color-01 {
  background-color: var(--color-01);
}

.bg-color-02 {
  background-color: var(--color-02);
}