.c-download {
  --downloadHeight: 4rem;
  display: flex;
  position: relative;
  align-items: center;
  width: 90%;
  height: var(--downloadHeight);
  background-color: var(--color-02);
  transition: all 100ms ease-in-out;
  margin-bottom: var(--spacing-04);
}

.c-download:last-child {
  margin-bottom: 0;
}

.c-download:hover {
  background-color: var(--color-03);
  color: var(--color-01);
}

.c-download__icon {
  display: inline-block;
  position: relative;
  background-color: var(--color-01);
  width: var(--downloadHeight);
  height: var(--downloadHeight);
}

.c-download__icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transform-origin: center;
  display: block;
}

.c-download__text {
  display: inline-block;
  /* border: 1px solid red; */
  padding-left: 1em;
}

.c-download__icon svg g path,
.c-download__icon svg g rect {
  fill: var(--color-light);
}