.c-link {
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 150ms ease-in-out;
}

.c-link:hover {
  border-bottom: 1px solid var(--color-dark);
}

.c-link--visible {
  border-bottom-color: rgba(0,0,0,0.3);
}

.c-iconLink {
  display: block;
}

.c-iconLink--right {
  text-align: right;
}

.c-iconLink svg {
  display: inline-block;
  transform: scale(1);
  margin-right: 0.85em;
}