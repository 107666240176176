.m-header {
  display: flex;
  padding: var(--body-padding);
}

.m-header ~ .m-breadcrumb {
  margin-top: var(--spacing-15);
}

.m-header__logo svg {
  display: block;
  height: 8.75rem;
  width: auto;
}

.m-header__nav {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}


/* DESKTOP MEDIUM */

@media screen and (max-width: 1330px) {
  .m-header {
    display: block;
  }
  .m-header__nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: var(--spacing-06);
    padding-bottom: var(--spacing-08);
    background-color: var(--color-01);
    color: var(--color-light);
    transition: all 500ms ease-in-out;
  }
  .m-header--navHidden .m-header__nav {
    opacity: 1;
    transform: translateX(100%);
    visibility: hidden;
  }
}

/* MOBILE  */

@media screen and (max-width: 640px) {
  .m-header__logo svg {
    height: 4.5rem;
  }
  .m-header {
    margin-bottom: var(--module-spacing-s);
  }
}


