.m-footer {
  display: flex;
  justify-content: space-between;
  /* margin-top: var(--spacing-20); */
  padding-top: 1em;
  padding-bottom: 2em;
}

.m-footer__navList {
  display: flex;
  justify-content: end;
  list-style-type: none;
  padding: 0;
  align-content: end;
}

.m-footer__navItem {
  margin-left: 2.35em;
}

.m-footer__col {
  grid-column: span 6;
}

@media screen and (max-width: 720px) {
  .m-footer {
    display: block;
  }
  .m-footer__navList {
    display: block;
    justify-content: end;
    list-style-type: none;
    padding: 0;
    align-content: end;
  }
  .m-footer__navItem {
    margin-left: 0;
    margin-top: 1.5em;
  }
}