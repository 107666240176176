.m-accordion {
  margin-bottom: auto;
}



/* The complete drawer: */

.m-accordion__details {
  grid-column: span 12;
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: var(--spacing-04) 0;
  width: 100%;
}

.m-accordion ~ .m-accordion .m-accordion__details {
  border-top: none;
}

.m-accordion ~ .m-figure {
  margin-top: var(--module-spacing-l);
}

.m-figure + .m-accordion .m-accordion__details {
  border-top: 1px solid;
}

.m-accordion:last-of-type .m-accordion__details {
  border-bottom: 1px solid;
  margin-bottom: var(--module-spacing-s);
}


  /* The drawer headline: */

.m-accordion__summary {
  display: flex;
  position: relative;
  transition: all 100ms ease-in-out;
  -webkit-tap-highlight-color: transparent;
}
.m-accordion__summary:hover {
  /* color: var(--color-01) */
}
.m-accordion__summary:hover svg g path {
  /* fill: var(--color-01) */
}

.m-accordion__hl {
  display: inline-block;
  margin-bottom: 0;
}

.m-accordion__summary svg {
  display: block;
  position: absolute;
  top: 35%;
  right: 0.3em;
  /* border: 1px solid red; */
  transform: rotate(90deg) scale(0.85) translateX(0em);
  transition: transform 300ms ease-in-out;
}

.m-accordion details summary:after {
  content: "";
}

.m-accordion details[open] .m-accordion__summary svg {
  transform: rotate(-90deg) scale(0.85) translateX(0em);
}



/* Entry within the accordion: */

.m-accordion__content {
  position: relative;
  padding-top: var(--spacing-10);
  padding-bottom: var(--spacing-15);
}

.m-accordion__row {
  display: flex;
  /* margin: var(--spacing-15) 0; */
  /* border: 1px dashed lime; */
}

.m-accordion__column {
  display: flex;
  width: auto;
  align-items: baseline;
  /* border: 1px dashed lime; */
}

.m-accordion__quote {
  display: block;
  position: absolute;
  top: 40%;
  right: 10%;
  transform: translateY(-50%);
  width: 50%;
  margin-top: 1.5em;
  max-width: 75ch;
  background: var(--color-02);
  padding: var(--spacing-10) var(--spacing-15) var(--spacing-15) var(--spacing-10);
  /* text-align: center; */
  /* display: none; */
  pointer-events: none;
}
.m-accordion__quote--invisible {
  opacity: 0;
}

.m-accordion__fullName {
  margin-right: var(--spacing-06);
}
.m-accordion__fullName--toggle {
  cursor: crosshair;
}
.m-accordion__fullName--toggle:hover {
  color: var(--color-dark)
}



/* Mediaqueries: */

@media screen and (max-width: 720px) {
  .m-accordion__hl {
    padding-right: 2em;
  }
  .m-accordion__content {
    padding-top: var(--spacing-06);
    padding-bottom: var(--spacing-04);
  }
  .m-accordion__row {
    display: block;
    margin-bottom: var(--spacing-04);
  }
  .m-accordion__column {
    display: block;
    width: 100%;
  }
  .m-accordion__quote {
    display: block;
    position: absolute;
    top: 40%;
    right: 4%;
    width: 80%;
    padding: var(--spacing-07) var(--spacing-10) var(--spacing-10) var(--spacing-06);
  }
  .m-accordion__fullName {

  }
}





/* Animate Summaries */
/* via comments @ https://css-tricks.com/two-issues-styling-the-details-element-and-how-to-solve-them/ */

details[open] summary ~ * {
  animation: sweep .25s ease-in-out;
}
@keyframes sweep {
  0%    {opacity: 0; margin-top: 0px}
  100%  {opacity: 1; margin-top: 0px}
}

/* Reset Summaries */
/* via https://css-tricks.com/two-issues-styling-the-details-element-and-how-to-solve-them/ */

details summary {
  cursor: pointer;
}

details summary > * {
  display: inline;
}

summary::-webkit-details-marker {
  display: none;
}