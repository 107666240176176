.m-slider {
  --sliderHeight: 0px;
  margin-bottom: var(--module-spacing-s);
  padding-bottom: var(--spacing-10);
  /* position: relative; */
  /* border: 1px dashed red; */
}

/* .swiper { */
/*   width: 100%; */
/*   height: 600px; */
/*   !* border: 5px dashed lime; *! */
/* } */

/* .swiper-wrapper { */

/* } */


.m-slider__stage {
  /* border: 5px dashed mediumpurple; */
  height: 70vh;
}

.m-slider__item {
  width: auto;
  height: 100%;
  /* border: 1px dashed blue; */
  /* overflow: auto; */
  padding-left: var(--body-padding);
}

.m-slider__item img {
  display: block;
  width: 100%;
  height: auto;


  height: 100%;
  width: 100%;

  width: auto;

  /* object-fit: contain; */
  /* margin-right: var(--body-padding); */
  /* border: 3px dashed red; */
}

.m-slider__caption {
  padding: 0 var(--body-padding);
  padding-top: var(--spacing-04);
}

.m-slider__caption {

  max-width: 50rem;

}






/* Flickity style overrides */

.flickity-page-dots .dot {
  background-color: transparent;
  border: 1px solid;
  width: 10px;
  height: 10px;
  margin: 0 0.4rem;
  opacity: 1;
  border-color: var(--color-01);
}

.flickity-page-dots .dot.is-selected {
  background-color: var(--color-01);
  border-color: var(--color-01);
}

.flickity-page-dots {
  top: calc(100% + 1rem);
  height: auto;
}


/* FLICKITY Navigation Buttons: */

.flickity-button {
  --flickity-button-size: 3.25rem;
  border: 1px solid var(--color-01);
  background: transparent url('../svg/ic-link-arrow-blue.svg') no-repeat center;
  width: var(--flickity-button-size);
  height: var(--flickity-button-size);
  top: calc(100% + var(--spacing-10));
  z-index: 100;
}
.flickity-button:hover {
  border: 1px solid var(--color-01);
  background: transparent url('../svg/ic-link-arrow-blue.svg') no-repeat center;
}
/* Button NEXT: */
.flickity-button.flickity-prev-next-button.next {
  right: var(--body-padding);
  left: auto;
}
/* Button PREVIOUS: */
.flickity-button.flickity-prev-next-button.previous {
  left: auto;
  right: 6rem;
  transform: scale(-1) translateY(50%);
}
/* Default Icon: */
.flickity-button svg {
  display: none;
}


/* MOBILE Mediaquery: */

@media screen and (max-width: 720px) {

  .m-slider {
    margin-bottom: var(--module-spacing-m);
  }

  .m-slider__stage {
    height: 40vh;
    padding-bottom: 0;
  }

  .m-slider__item {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .m-slider__item img {
    width: 100%;
    height: 100%;
    padding: 0;
    object-fit: contain;
  }

  .flickity-button {
    --flickity-button-size: 3.25rem;
    border: 1px solid var(--color-01);
    background: transparent url('../svg/ic-link-arrow-blue.svg') no-repeat center;
    width: var(--flickity-button-size);
    height: var(--flickity-button-size);
    top: calc(100% + var(--spacing-15));
    z-index: 100;
  }
  .flickity-button.flickity-prev-next-button.next {
    transform: scale(0.7) translateY(0);
    right: var(--body-padding);
    left: auto;
  }
  .flickity-button.flickity-prev-next-button.previous {
    transform: scale(-0.7) translateY(0);
    left: auto;
    right: 3.75rem;
  }
}