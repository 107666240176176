.m-articleListing {

  padding: 0 var(--body-padding);
  /* border: 1px solid green; */
}

.m-articleListing__item {
  display: block;
  width: 30.5%;
  margin-bottom: var(--spacing-20);
  /* border: 1px solid red; */
}

.m-articleListing__item h2 {
  padding-right: 1em;
}

.m-articleListing__item img {
  display: block;
  width: 80%;
  height: auto;
  margin-bottom: var(--spacing-03);
}

.m-articleListing__categories {
  display: block;
  margin-bottom: 0.35em;
}

/* Masonry/Isotope specific class */
.m-articleListing__gutter {
  width: 2.15%;
}



/* Mediaqueries */

@media screen and (max-width: 1400px) {
  .m-articleListing__item {
    width: 30%;
  }
  .m-articleListing__gutter {
    width: 2.15%;
  }
}

@media screen and (max-width: 1040px) {
  .m-articleListing__item {
    width: 45%;
    margin-bottom: var(--spacing-15);
  }
  .m-articleListing__item img{
    width: 64%;
  }
  .m-articleListing__gutter {
    width: 3%;
  }
}

@media screen and (max-width: 720px) {
  .m-articleListing__item {
    width: 100%;
    margin-bottom: var(--spacing-15);
  }
  .m-articleListing__item img{
    width: 64%;
  }
  .m-articleListing__gutter {
    width: 0%;
  }
}