/* BOTTOM MARGINS: */

.margin--b01 {
  margin-bottom: var(--spacing-01);
}
.margin--b02 {
  margin-bottom: var(--spacing-02);
}
.margin--b03 {
  margin-bottom: var(--spacing-03);
}
.margin--b04 {
  margin-bottom: var(--spacing-04);
}
.margin--b05 {
  margin-bottom: var(--spacing-05);
}
.margin--b06 {
  margin-bottom: var(--spacing-06);
}
.margin--b07 {
  margin-bottom: var(--spacing-07);
}
.margin--b08 {
  margin-bottom: var(--spacing-08);
}
.margin--b09 {
  margin-bottom: var(--spacing-09);
}
.margin--b10 {
  margin-bottom: var(--spacing-10);
}
.margin--b15 {
  margin-bottom: var(--spacing-15);
}
.margin--b20 {
  margin-bottom: var(--spacing-20);
}
.margin--b30 {
  margin-bottom: var(--spacing-30);
}



/* TOP MARGINS: */

.margin--t01 {
  margin-top: var(--spacing-01);
}
.margin--t02 {
  margin-top: var(--spacing-02);
}
.margin--t03 {
  margin-top: var(--spacing-03);
}
.margin--t04 {
  margin-top: var(--spacing-04);
}
.margin--t05 {
  margin-top: var(--spacing-05);
}
.margin--t06 {
  margin-top: var(--spacing-06);
}
.margin--t07 {
  margin-top: var(--spacing-07);
}
.margin--t08 {
  margin-top: var(--spacing-08);
}
.margin--t09 {
  margin-top: var(--spacing-09);
}
.margin--t10 {
  margin-top: var(--spacing-10);
}
.margin--t15 {
  margin-top: var(--spacing-15);
}
.margin--t20 {
  margin-top: var(--spacing-20);
}
.margin--t30 {
  margin-top: var(--spacing-30);
}



/* MOBILE Mediaquery: */

@media screen and (max-width: 720px) {
  /* BOTTOM MARGINS: */
  .margin--b08 {
    margin-bottom: var(--spacing-06);
  }
  .margin--b10 {
    margin-bottom: var(--spacing-05);
  }
  .margin--b30 {
    margin-bottom: var(--spacing-15);
  }
  .margin--b15 {
    margin-bottom: var(--spacing-06);
  }
  /* TOP MARGINS: */
  .margin--t20 {
    margin-top: var(--spacing-10);
  }
}