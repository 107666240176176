.m-portfolio {
  margin: 0 var(--body-padding);
}

.m-portfolio__item {
  display: block;
  width: 27%;
  margin-bottom: 5vw;
  margin-right: 6vw;
  transition: opacity 200ms ease-in-out;
}

.m-portfolio__item:hover {
  opacity: 1;
}

.m-portfolio__item img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: var(--spacing-02);
}

.m-portfolio__text {
  padding-right: 2em;
}

.m-portfolio__link {
  border: 0;
  background-color: transparent;
  text-align: left;
  padding: 0;
  color: var(--color-01);
  cursor: pointer;
  transition: color 100ms ease-in-out;
}

.m-portfolio__link:hover {
  color: var(--color-dark);
}

.m-portfolio__link svg {
  display: none;
  width: 1.25em;
  position: relative;
  top: 0%;
  left: 0.4em;
}



.pswp {
  --pswp-bg: #fff;
}

.pswp__dynamic-caption {
  color: var(--color-dark);
  margin-top: 0;
  padding-top: 0;
  padding-left: var(--spacing-08);
}

.pswp__dynamic-caption--below {
  background-color: #fff;
  color: var(--color-dark);
  margin-top: 0;
  padding: var(--spacing-04) var(--spacing-08) var(--spacing-04) 0;
}

.pswp__button--arrow {
  background: url('../svg/ic-link-arrow.svg') no-repeat center !important;
  transition: opacity 150ms ease-in-out;
}

.pswp__button--arrow:hover,
.pswp__button--arrow:active {
  background: url('../svg/ic-link-arrow.svg') no-repeat center !important;
  opacity: 0.75;
}

.pswp__button--arrow--prev {
  transform: scale(-1);
}

.pswp__button--close {
  background: url('../svg/ic-close.svg') no-repeat center !important;
}

.pswp__button--close svg {
  display: none;
}

.pswp__button--zoom {
  background: url('../svg/ic-zoom.svg') no-repeat center !important;
}

.pswp__button--zoom svg {
  display: none;
}

.pswp__counter {
  text-shadow: none;
  color: var(--color-dark);
  font-size: var(--text-xs);
  font-family: "Sans", sans-serif;
}



/* MOBILE Mediaquery: */

@media screen and (max-width: 720px) {
  .m-portfolio__item {
    width: 80%;
    margin-bottom: var(--spacing-08);
    margin-right: 0;
  }
  .pswp__dynamic-caption {
    background-color: var(--color-light);
  }
}

/* DESKTOP LARGE Mediaquery: */

@media screen and (min-width: 1800px) {
  .m-portfolio__item {
    display: block;
    width: 27%;
    margin-bottom: 5vw;
    margin-right: 6vw;
    transition: opacity 200ms ease-in-out;
  }
}

@media screen and (max-width: 1024px) {
  .pswp__dynamic-caption--below {
    background-color: #fff;
    color: var(--color-dark);
    margin-top: 0;
    padding: var(--spacing-04) var(--spacing-08) var(--spacing-04) var(--body-padding);
  }
}


