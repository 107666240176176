.m-introImages {
  margin-top: var(--spacing-08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-30);
}

.m-introImages ~ .m-breadcrumb {
  margin-top: var(--spacing-15);
}

.m-introImages__item {
  width: 25%;
}

.m-introImages__item img {
  display: block;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 720px){
  .m-introImages {
    margin-top: var(--spacing-06);
    margin-bottom: var(--spacing-10);
    padding: 0 var(--body-padding);
  }
  .m-introImages__item {
    width: 30%;
  }
}