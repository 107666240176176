.m-infoBox {
  margin-bottom: var(--module-spacing-l);
}

.m-infoBox__content {
  grid-column-start: 3;
  grid-column-end: 11;
  padding: var(--spacing-15) var(--spacing-15) var(--spacing-20) var(--spacing-15);
  background-color: var(--color-02);
  color: var(--color-01);
}

.m-infoBox__headline {
  margin-bottom: 1.5em;
}

.m-infoBox__text {
  columns: 2;
  column-gap: var(--spacing-15);
}