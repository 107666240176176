/* Styles */

.txt-sans {
  font-family: "Sans", sans-serif;
}

.txt-serif {
  font-family: "Serif", serif;
}

.txt-serif-italic {
  font-family: "Serif", serif;
  font-style: italic;
}

.txt-serif-display {
  font-family: "Serif Display", serif;
}



/* Sizes */

.txt--xs {
  font-size: var(--text-xs);
  line-height: var(--leading-m);
  letter-spacing: 0.065em;
}

.txt--s {
  font-size: var(--text-s);
  line-height: var(--leading-m);
}

.txt--m {
  font-size: var(--text-m);
  line-height: var(--leading-m);
}

.txt--l {
  font-size: var(--text-l);
  line-height: var(--leading-m);
}

.txt--xl {
  font-size: var(--text-xl);
}

.txt--2xl {
  font-size: var(--text-2xl);
}

.txt--3xl {
  font-size: var(--text-3xl);
  line-height: var(--leading-xs);
}



/* Helpers */

.txt--uppercase {
  text-transform: uppercase;
}

.txt--center {
  text-align: center;
}

.txt--leading-m {
  line-height: var(--leading-m);
}

.txt--leading-l {
  line-height: var(--leading-l);
}

/* Mediaqueries */

@media screen and (max-width: 720px) {
  .txt--3xl {
    font-size: var(--text-xl);
    line-height: var(--leading-xs);
  }
}