.m-teaserPage {
  padding: var(--spacing-20) 10%;
}



.m-teaserPage__content {
  display: flex;
  align-items: center;
}



.m-teaserPage__column {
  width: 50%;
}
.m-teaserPage__column:first-child {
  width: 60%;
  padding-right: var(--spacing-15);
  padding-bottom: 4%;
}
.m-teaserPage__column:last-child {
  width: 40%;
}
.m-teaserPage__column h2 {
  max-width: 35ch;
}
.m-teaserPage__column p {
  max-width: 42ch;
}



.m-teaserPage__image img {
  display: block;
  width: 100%;
  height: auto;
}



/* MOBILE Mediaquery: */

@media screen and (max-width: 720px) {
  .m-teaserPage__content {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .m-teaserPage__column {
    width: 100%;
  }
  .m-teaserPage__column:first-child {
    width: 100%;
    padding-right: 0;
  }
  .m-teaserPage__column:last-child {
    width: 100%;
    padding-right: 0;
    padding-bottom: 4%;
  }
  .m-teaserPage__column h2 {
    max-width: 100%;
  }
  .m-teaserPage__column p {
    max-width: 100%;
  }
}