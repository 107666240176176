.m-teaser {
  margin-bottom: var(--module-spacing-l);
  margin-top: var(--spacing-20);
}



.m-teaser__moduleHeadline {
  grid-column: span 12;
  margin-bottom: 1em;
}



.m-teaser__list {
  list-style-type: none;
}



.m-teaser__item {
  display: flex;
  grid-column: span 6;
  margin-bottom: var(--grid-colgap);
}



.m-teaser__link {
  display: block;
  width: 100%;
  padding: var(--spacing-10) var(--spacing-15) var(--spacing-15) var(--spacing-15);
  background-color: var(--color-02);
  transition: background-color 200ms ease-in-out;
}

.m-teaser--fullWidth .m-teaser__link {
  grid-column: span 12;
  margin-bottom: var(--grid-colgap);
}

.m-teaser--halfWidth .m-teaser__link {
  grid-column: span 6;
  margin-bottom: var(--grid-colgap);
}

.m-teaser__link:hover {
  background-color: var(--color-03);
}



.m-teaser__label {
  display: block;
  margin-bottom: var(--spacing-03);
}

.m-teaser__headline {
  margin-bottom: var(--text-m);
}

.m-teaser__copy {
  line-height: var(--leading-l);
  padding-right: 2em;
  margin-bottom: var(--text-xl);
  max-width: 70ch;
}

.m-teaser__more svg {
  margin-right: 0.25em;
  margin-bottom: 0.09em;
}

.m-teaser__more svg g path {
  fill: var(--color-01);
}



/* MOBILE Mediaquery: */

@media screen and (max-width: 640px) {
  .m-teaser {
    margin-top: var(--spacing-15);
    margin-bottom: var(--spacing-10);
  }
  .m-teaser__item {
    display: block;
    grid-column: span 12;
    margin-bottom: 0;
  }
  .m-teaser__copy {
    line-height: var(--leading-l);
    padding-right: 0;
    margin-bottom: var(--spacing-07);
    max-width: 70ch;
  }
  .m-teaser__link {
    padding: var(--spacing-06) var(--spacing-06) var(--spacing-10) var(--spacing-06);
    margin-bottom: var(--grid-colgap);
  }
  .m-teaser--halfWidth .m-teaser__link {
    grid-column: span 12;
    margin-bottom: var(--grid-colgap);
  }
}



/* DESKTOP LARGE Mediaquery: */

@media screen and (min-width: 1800px) {
  .m-teaser__item {

  }
  .m-teaser--fullWidth .m-teaser__link {
    grid-column: span 6;
    margin-bottom: var(--grid-colgap);
  }
}