.c-image {
  display: block;
  width: 100%;
  border: 1px solid red;
  margin: 0;
}

.c-image figure {
  margin: 0;
}

.c-image figure img {
  margin: 0;
}

.c-image figure figcaption {
  margin-top: 0.5em;
  font-size: var(--text-xxs);
}
