/* Module settings */

:root {

  --module-spacing-s: var(--spacing-10);
  --module-spacing-m: var(--spacing-15);
  --module-spacing-l: var(--spacing-20);
  --module-spacing-xl: var(--spacing-30);
  --module-spacing-2xl: 30rem;

}
