.m-institution {
  margin-bottom: var(--module-spacing-s);
}



.m-institution__headline {
  grid-column: span 12;
  margin-bottom: var(--spacing-10);
}

.m-institution__label {
  grid-column: span 12;
  margin-bottom: var(--spacing-02);
}

.m-institution__text {
  grid-column: span 12;
}