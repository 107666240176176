.m-pullQuote {
  margin-bottom: var(--spacing-10);
}

.m-pullQuote__quote {
  grid-column-start: 3;
  grid-column-end: 11;
  margin: 0;
}

@media screen and (max-width: 720px) {
  .m-pullQuote__quote {
    grid-column-start: 1;
    grid-column-end: 12;
  }
}