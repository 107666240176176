.m-breadcrumb {
  margin-bottom: var(--spacing-15);
  padding: 0 var(--body-padding);
}

.m-breadcrumb__list {
  list-style-type: none;
  padding: 0;
}



.m-breadcrumb__entry {
  display: inline-block;
}

.m-breadcrumb__entry:before {
  content: "";
  display: inline-block;
  width: 1.8rem;
  height: 0.57rem;
  padding-bottom: 0.05rem;
  margin-left: 0.4rem;
  margin-right: 0.3rem;
  background: url("../svg/ic-link-arrow.svg") no-repeat center;
}

.m-breadcrumb__entry:first-child:before {
  display: none;
}



.m-breadcrumb__link {

}

@media screen and (max-width: 640px) {
  .m-breadcrumb {
    display: none;
  }
}