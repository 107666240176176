.c-nav {
  display: block;
}



/* Main Navigation */

.c-nav-main {
  align-self: flex-end;
}

.c-nav-main__list {
  display: flex;
  justify-content: end;
  list-style-type: none;
  padding: 0;
}

.c-nav-main__item {
  margin-left: 4em;
}

.c-nav-main__link {
  font-size: 2.375rem;
}

@media screen and (max-width: 1330px) {
  .c-nav-main {
    align-self: flex-start;
    padding-top: var(--spacing-20);
  }
  .c-nav-main__list {
    display: block;
  }
  .c-nav-main__item {
    margin-left: 0;
    margin-top: var(--spacing-06);
  }
}



/* Meta Navigation */

.c-nav-meta {
  align-self: flex-end;
}

.c-nav-meta__list {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.c-nav-meta__item {
  margin-left: 2.35em;
}

.c-nav-meta__link {

}

@media screen and (max-width: 1330px) {
  .c-nav-meta {
    align-self: flex-start;
    padding-top: var(--spacing-10);
  }
  .c-nav-meta__list {
    display: block;
  }
  .c-nav-meta__item {
    margin-left: 0;
  }
}



/* Navigation mobile toggle */

.c-nav-toggle {
  display: none; /* hide on desktop */
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: var(--spacing-08);
  right: var(--spacing-08);
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.c-nav-toggle__line {
  position: absolute;
  left: 0;
  height: 1.5px;
  width: 100%;
  background-color: var(--color-light);
  transition: all 200ms ease-in-out;
}

.c-nav-toggle__line:nth-child(1) {
  transform: translateY(0) rotate(45deg);
}

.c-nav-toggle__line:nth-child(2) {
  opacity: 0;
}

.c-nav-toggle__line:nth-child(3) {
  transform: translateY(0) rotate(-45deg);
}

.m-header--navHidden .c-nav-toggle__line {
  background-color: var(--color-dark);
}

.m-header--navHidden .c-nav-toggle__line:nth-child(1) {
  transform: translateY(-10px);
}

.m-header--navHidden .c-nav-toggle__line:nth-child(2) {
  opacity: 1;
}

.m-header--navHidden .c-nav-toggle__line:nth-child(3) {
  transform: translateY(10px);
}



@media screen and (max-width: 1330px) {
  .c-nav-toggle {
    display: block;
  }
  .c-nav-main__link.c-link:hover,
  .c-nav-meta__link.c-link:hover{
    border-bottom: 1px solid var(--color-light);
  }
  .c-nav-meta__item {
    margin-bottom: var(--spacing-06);
  }
}