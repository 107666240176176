.c-button {
  display: inline-block;
  padding: 1em 2em;
  border-radius: 3em;
  white-space: nowrap;
  font-size: var(--text-s);
  font-family: var(--font-sans);
}

.c-button--primary {
  background-color: var(--color-01);
  color: var(--color-light);
}