.m-text-image {
  margin-bottom: var(--module-spacing-l);
}

.m-text-image .c-richtext {
  grid-column: span 6;
}

.m-text-image .c-image {
  grid-column: span 6;
}



/* Tablet */

@media screen and (max-width: 1300px) {
  .m-text-image .c-richtext {
    grid-column: span 7;
  }

  .m-text-image .c-image {
    grid-column: span 5;
  }
}



/* Mobile */

@media screen and (max-width: 600px) {

  .m-text-image .c-richtext {
    grid-column: span 12;
  }

  .m-text-image .c-image {
    grid-column: span 12;
  }

}

