/* Colours */

:root {

  --color-dark: #000;
  --color-dark--soft: rgba(0,0,0,0.35);

  --color-light: #fff;

  --color-01: #0000D9;

  --color-02: #F6F4EF;
  --color-02--darker: #ECEAE5;

  --color-03: #F1F5F4;

}
