* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 640px) {

}

body {
  background: #fff;
  height: 100%;
  font-family: "Sans", sans-serif;
  margin: 0;
}

@media screen and (min-width: 1860px) {
  html {
    font-size: 18px;
  }
}


main {

}

figure {
  padding:0;
  margin: 0;
}

h1, h2, h3, h4, p, ul, ol {
  margin: 0;
  font-size: 1em;
  font-weight: normal;
}

h1 {

}

h2 {

}


h3 {

}

p {

}

a {
  color: currentColor;
  text-decoration: none;
}


.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}


q {
  quotes: none;
}

button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
