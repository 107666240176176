.m-article {
  margin-bottom: var(--module-spacing-l);
}

.m-article--contentBuilder {
  margin-bottom: var(--module-spacing-xl);
}

/* .m-article section:last-of-type { */
/*   margin-bottom: 0; */
/* } */

.m-article__headline {
  max-width: 35ch;
  margin-bottom: 0.5em;
}

.m-article__meta {
  grid-column: span 11;
  grid-row: 1;
  margin-bottom: var(--spacing-15);
}

.m-article__content {
  grid-column-start: 5;
  grid-column-end: 12;
  grid-row: 2;
}

.m-article__side {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 2;
}

.m-article__image {
  padding-right: var(--body-padding);
}

.m-article__image img {
  display: block;
  width: 100%;
  height: auto;
}

.m-article__text {
  grid-column: span 7 !important;
  grid-row: 3;
  margin-bottom: var(--module-spacing-s);
}



.m-article--magazine .m-article__meta {
  grid-column: span 12;
  grid-row: auto;
  margin-bottom: var(--spacing-05);
  grid-row: 2;
}

.m-article--magazine .m-article__image {
  grid-column: span 7;
  grid-row: 3;
  margin-bottom: var(--spacing-05);
}

.m-article--magazine .m-article__headline {
  grid-column: span 8;
  grid-row: 1;
  margin-bottom: var(--spacing-05);
}


.m-article__backLink {
  grid-column: span 12;
  grid-row: 3;
  margin-bottom: var(--spacing-30);
}

.m-article__backLink svg {
  display: inline-block;
  transform: scale(-1);
  margin-right: 0.85em;
}





/* Mediaqueries */

@media screen and (max-width: 720px) {

  .m-article__headline {
    grid-column: span 12;
    grid-row: 1;
    margin-bottom: 0.5em;
  }

  .m-article__meta {
    grid-column: span 12;
    grid-row: 2;
    margin-bottom: var(--spacing-15);
  }

  .m-article__content {
    grid-column-start: 1;
    grid-column-end: 12;
    grid-row: 4;
  }

  .m-article__side {
    grid-column-start: 1;
    grid-column-end: 12;
    grid-row: 3;
  }

  .m-article__image {
    padding-right: 0;
  }
  .m-article--magazine .m-article__image {
    grid-column: span 12;
    grid-row: 3;
    margin-bottom: var(--spacing-05);
  }

}