.m-filter {
  margin-bottom: var(--spacing-15);
}

.m-filter__dropdown {
  grid-column: span 5;
  border: 1px solid;
  border-radius: 0;
  -webkit-appearance: none;
  padding: 1em 4em 1em 1.25em;
  background: transparent url('../svg/ic-link-arrow-down.svg') no-repeat right 1.25em center;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (max-width: 720px) {
  .m-filter__dropdown {
    grid-column: span 12;
  }
}