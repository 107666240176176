/** Font Setup **/

@font-face {
  font-family: "Serif Display";
  src: url("../fonts/MinionCondensedDisplayRegular/font.woff") format("woff"),
       url("../fonts/MinionCondensedDisplayRegular/font.woff2") format("woff2");
}

@font-face {
  font-family: "Serif";
  src: url("../fonts/MinionRegular/font.woff") format("woff"),
       url("../fonts/MinionRegular/font.woff2") format("woff2");
}

@font-face {
  font-family: "Serif";
  font-style: italic;
  src: url("../fonts/MinionItalic/font.woff") format("woff"),
       url("../fonts/MinionItalic/font.woff2") format("woff2");
}

@font-face {
  font-family: "Sans";
  src: url("../fonts/NeueHaasGroteskTextPro55Roman/font.woff") format("woff"),
       url("../fonts/NeueHaasGroteskTextPro55Roman/font.woff2") format("woff2");
}

@font-face {
  font-family: "Sans";
  font-style: italic;
  src: url("../fonts/NeueHaasGroteskTextPro56Italic/font.woff") format("woff"),
  url("../fonts/NeueHaasGroteskTextPro56Italic/font.woff2") format("woff2");
}


/* Typography */

:root {

  --font-sans: "TS mono", Arial, sans-serif;
  --font-sans-bold: "Aeonik Fono", Arial, sans-serif;
  --font-serif: Times, 'Times New Roman', serif;

  --weight-normal: 400;
  --weight-bold: 500;

  --text-xxs: 0.7rem; /* 14px */
  --text-xs:  0.785rem; /* 14px */
  --text-s:   1.125rem; /* 18px */
  --text-m:   1.4rem; /* 24px */
  --text-l:   1.6rem; /* 32px */
  --text-xl:  2.5rem; /* 40px */
  --text-2xl: 3.75rem; /* 60px */
  --text-3xl: 5.000rem; /* 80px */
  --text-4xl: 7.000rem; /* 112px */

  --leading-xs:  1.15; /* 17 px */
  --leading-s:   1.2;
  --leading-m:   1.4;
  --leading-l:   1.65; /* 35 px */
  --leading-xl:  1.14; /* 48 px */
  --leading-2xl: 1.07; /* 58 px */
  --leading-3xl: 1.05; /* 78 px */
  --leading-4xl: 0.93; /* 110 px */

}


/* Mobile */

@media screen and (max-width: 600px) {
  :root {
    --text-xs:  0.825rem; /* 14px */
    --text-s:   0.85rem; /* 18px */
    --text-m:   1.0rem; /* 24px */
    --text-l:   1.34rem; /* 32px */
    --text-xl:  1.8rem; /* 40px */
    --text-2xl: 2.5rem; /* 60px */
  }
}
