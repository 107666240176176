.m-textExpander {
  margin-top: calc(var(--spacing-10) * -1);
  margin-bottom: var(--module-spacing-m);
}

.m-textExpander__content {
  height: 0;
  overflow: hidden;
}

.m-textExpander__content--visible {
  height: auto;
  overflow: auto;
  padding-bottom: 2.2em;
}

.m-textExpander__button {
  display: block;
  grid-row-start: 2;
  grid-column: span 12;
}

@media screen and (max-width: 720px) {
  .m-textExpander {
    margin-top: calc(var(--spacing-02) * -1);
  }
}