/* RICHTEXT */

.c-richtext {

}



/* Basic HTML text tags */

.c-richtext > h2 {
  grid-column: span 10;
  font-size: var(--text-xl);
  line-height: var(--leading-m);
  margin-bottom: 1em;
  max-width: 56ch;
}

.c-richtext > p {
  grid-column: span 9;
  align-self: start;
  max-width: 73ch;
  margin-bottom: 1.5em;
  font-family: "Serif", serif;
  font-size: var(--text-l);
  line-height: var(--leading-m);
}

.c-richtext > p:last-child {
  margin-bottom: 0;
}

.c-richtext > p strong,
.c-richtext > p b {
  font-weight: bold;
}

.c-richtext > p em,
.c-richtext > p i {
  font-style: italic;
}



/* Links and anchors */

.c-richtext > p a {
  border-bottom: 1px solid currentColor;
}

.c-richtext > p a:hover {
  border-bottom: 2px solid currentColor;
}



/* Blockquotes and citations */

.c-richtext > p cite {
  font-style: italic;
}

.c-richtext > blockquote {
  /* border: 1px solid red; */
  max-width: 120ch;
  grid-column-start: 3;
  grid-column-end: 11;
  padding: 0;
  margin: 0;
}

.c-richtext p + blockquote {
  margin-top: -2.3rem;
}

.c-richtext blockquote p {
  font-family: "Serif Display", serif;
  font-size: var(--text-2xl);
  margin: 1em 0;
}

.c-richtext__blockquote-source {
  display: block;
  margin-bottom: 1.5em;
  text-align: left;
  font-size: var(--text-s);
}



/* Lists */

.c-richtext ol,
.c-richtext ul {
  grid-column: span 9;
  max-width: 73ch;
  align-self: start;
  margin-bottom: 1.5em;
  font-family: "Serif", serif;
  font-size: var(--text-l);
  line-height: var(--leading-m);
}

.c-richtext ol li,
.c-richtext ul li {

}



/* Custom Styles via CKEditor*/

.c-richtext p.note {
  font-style: italic;
  font-size: var(--text-s);
  max-width: 70ch;
}



/* MOBILE VIEW */

@media screen and (max-width: 640px) {
  .c-richtext > p,
  .c-richtext > h2,
  .c-richtext > blockquote {
    grid-column: span 12;
  }
  .c-richtext p {
    /* font-size: var(--text-m); */
    line-height: 1.45;
  }
  .c-richtext blockquote p {
    /* font-size: var(--text-m); */
    line-height: var(--leading-s);
  }
  .c-richtext h2 {
    /* font-size: var(--text-m); */
  }
  .c-richtext blockquote p {
    /* font-size: var(--text-xl); */
  }
}
