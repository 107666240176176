:root {
  --grid-columns: 12;
  --grid-colgap: var(--body-padding);
}

@media screen and (max-width: 640px) {
  :root {
    --grid-columns: 12;
    /* --grid-colgap: 0; */
  }
}

.c-grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  grid-column-gap: var(--grid-colgap);
  grid-row-gap: var(--row-gap);
  /* grid-auto-flow: dense; */
  padding-left: var(--body-padding);
  padding-right: var(--body-padding);
}

.c-grid__span1 {
  grid-column: span 1;
}

.c-grid__span2 {
  grid-column: span 2;
}

.c-grid__span3 {
  grid-column: span 3;
}

.c-grid__span4 {
  grid-column: span 4;
}

.c-grid__span5 {
  grid-column: span 5;
}

.c-grid__span6 {
  grid-column: span 6;
}

.c-grid__span7 {
  grid-column: span 7;
}

.c-grid__span8 {
  grid-column: span 8;
}

.c-grid__span9 {
  grid-column: span 9;
}

.c-grid__span10 {
  grid-column: span 10;
}

.c-grid__span11 {
  grid-column: span 11;
}

.c-grid__span12 {
  grid-column: span 12;
}



/* MOBILE Mediaquery */

@media screen and (max-width: 720px) {
  .c-grid__span8 {
    grid-column: span 12;
  }
}