:root {
  --body-padding: var(--spacing-08);
}

/* Mobile */

@media screen and (max-width: 600px) {
  :root {
    --body-padding: var(--spacing-03);
  }
}