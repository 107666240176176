/* Spacing */

:root {

  --spacing-01: 0.25rem; /* 4px */
  --spacing-02: 0.5rem;  /* 8px */
  --spacing-03: 0.75rem; /* 12px */
  --spacing-04: 1rem;    /* 16px */
  --spacing-05: 1.25rem; /* 20px */
  --spacing-06: 1.5rem;  /* 24px */
  --spacing-07: 1.75rem; /* 28px */
  --spacing-08: 2rem;    /* 32px */
  --spacing-09: 2.25rem; /* 36px */
  --spacing-10: 2.5rem;  /* 40px */
  --spacing-15: 3.75rem; /* 60px */
  --spacing-20: 5rem;    /* 80px */
  --spacing-30: 7.5rem;  /* 120px */

}



/* Tablet */

@media screen and (max-width: 1300px) {
  :root {

  }
}



/* Mobile */

@media screen and (max-width: 600px) {
  :root {

  }
}
