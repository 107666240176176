.m-figure {
  margin-bottom: var(--module-spacing-l);
}

.m-figure__content img {
  grid-column-start: 5;
  grid-column-end: span 7;
  display: block;
  width: 100%;
  height: auto;
}

.m-figure--right .m-figure__content img {
  grid-column-start: 4;
  grid-column-end: span 7;
  display: block;
  width: 100%;
  height: auto;
}

.m-figure--left .m-figure__content img {
  grid-column-start: 1;
}

.m-figure--fullWidth .m-figure__content img,
.m-figure--portrait.m-figure--fullWidth .m-figure__content img, {
  grid-column-start: 1;
  grid-column-end: span 12;
}

.m-figure--portrait .m-figure__content img {
  grid-column-end: span 6;
}

.m-figure--portrait.m-figure--left .m-figure__content img {
  grid-column-start: 3;
  grid-column-end: span 6;
}



.m-figure__caption {
  display: flex;
  grid-column-start: 2;
  grid-column-end: span 3;
  align-content: flex-start;
  vertical-align: bottom;
  text-align: right;
}

.m-figure--right .m-figure__caption {
  grid-row: 1;
  grid-column-start: 2;
  grid-column-end: span 2;
}

.m-figure--left .m-figure__caption {
  grid-column-start: 8;
  grid-column-end: span 2;
  text-align: left;
}

.m-figure--fullWidth .m-figure__caption {
  grid-column-start: 1;
  grid-column-end: span 7;
  text-align: left;
}

.m-figure__text {
  max-width: 70ch;
  display: block;
  width: 100%;
  align-self: end;
  padding-top: 0.35em;
}



/* Mediaqueries */

@media screen and (max-width: 720px) {

  .m-figure {
    margin-bottom: var(--module-spacing-s);
  }

  .m-figure__content img {
    grid-column-start: 1;
    grid-column-end: span 12;
  }

  .m-figure--right .m-figure__content img {
    grid-column-start: 1;
    grid-column-end: span 12;
    display: block;
    width: 100%;
    height: auto;
  }

  .m-figure--left .m-figure__content img {
    grid-column-start: 1;
    grid-column-end: span 12;
  }

  .m-figure--fullWidth .m-figure__content img {
    grid-column-start: 1;
    grid-column-end: span 12;
  }

  .m-figure--portrait .m-figure__content img {
    grid-column-start: 1;
    grid-column-end: span 12;
  }



  .m-figure__caption {
    grid-column-start: 1;
    grid-column-end: span 12;
    text-align: left;
  }

  .m-figure--right .m-figure__caption {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: span 12;
  }

  .m-figure--left .m-figure__caption {
    grid-column-start: 1;
    grid-column-end: span 12;
    text-align: left;
  }

  .m-figure--fullWidth .m-figure__caption {
    grid-column-start: 1;
    grid-column-end: span 12;
    text-align: left;
  }

}